import {HttpClient, HttpHeaders} from '@angular/common/http'
import {inject, Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {BehaviorSubject, Observable, catchError, map, of, tap, firstValueFrom} from 'rxjs'
import {MEMBER_MANAGER_REFRESH, MEMBER_MANAGER_TOKEN} from '../shared/consts/storage.const'
import {AuthState, IAuthUser, LoginResponse} from './auth.types'
import {API_URL_HEADER, ApiType} from '../@core/http/interceptors/api-end-point.interceptor'
import {switchMap} from 'rxjs/operators'

@Injectable({providedIn: 'root'})
export class AuthService {
    private http: HttpClient = inject(HttpClient)
    private router: Router = inject(Router)
    private authHeaders = new HttpHeaders().set(API_URL_HEADER, ApiType.AUTH)
    private authzHeaders = new HttpHeaders().set(API_URL_HEADER, ApiType.AUTHZ)

    private authState = new BehaviorSubject<AuthState>({
        user: null,
        token: localStorage.getItem(MEMBER_MANAGER_TOKEN),
        refreshToken: localStorage.getItem(MEMBER_MANAGER_REFRESH),
        isAuthenticated: !!localStorage.getItem(MEMBER_MANAGER_TOKEN)
    })

    readonly authUser$ = this.authState.pipe(map(state => state.user))
    readonly user$ = this.authState.pipe(map(state => state.user?.user))
    readonly account$ = this.authState.pipe(map(state => state.user?.account))
    readonly token$ = this.authState.pipe(map(state => state.token))
    readonly isAuthenticated$ = this.authState.pipe(map(state => state.isAuthenticated))

    login(email: string, password: string): Observable<string> {
        return this.http.post<LoginResponse>(
            `auth/login`,
            {email, password},
            {headers: this.authHeaders}
        ).pipe(
            tap(({access_token, refresh_token, user}) => {
                this.updateAuthState({
                    token: access_token,
                    refreshToken: refresh_token,
                    user: {user, account: null},
                    isAuthenticated: true
                })
            }),
            switchMap(response => {
                // Fetch user data immediately after successful login
                return this.fetchMe().pipe(
                    // Return the access token after fetching user data
                    map(() => response.access_token)
                )
            }),
            catchError(error => {
                this.updateAuthState({
                    token: null,
                    refreshToken: null,
                    user: null,
                    isAuthenticated: false
                })
                throw error
            })
        )
    }

    fetchMe(): Observable<IAuthUser> {
        const token = this.authState.value.token
        if (!token) {
            return of(null)
        }

        return this.http.get<IAuthUser>(
            `accounts/me`,
            {headers: this.authzHeaders}
        ).pipe(
            tap(authUser => {
                this.updateAuthState({
                    ...this.authState.value,
                    user: authUser
                })
            })
        )
    }

    logout(): Observable<boolean> {
        return this.http.post<void>(
            `auth/logout`,
            {refresh_token: this.authState.value.refreshToken},
            {headers: this.authHeaders}
        ).pipe(
            tap(() => {
                this.updateAuthState({
                    token: null,
                    refreshToken: null,
                    user: null,
                    isAuthenticated: false
                })
            }),
            map(() => true),
            tap(() => this.router.navigate(['/auth'], {replaceUrl: true}))
        )
    }

    isSuperAdmin(): Observable<boolean> {
        return this.user$.pipe(
            map(user => !!user?.is_super_admin)
        )
    }

    hasRole(roleId: string): Observable<boolean> {
        return this.account$.pipe(
            map(account => account?.role_id === roleId)
        )
    }

    updateAuthState(newState: AuthState): void {
        // Update localStorage
        if (newState.token) {
            localStorage.setItem(MEMBER_MANAGER_TOKEN, newState.token)
        } else {
            localStorage.removeItem(MEMBER_MANAGER_TOKEN)
        }

        if (newState.refreshToken) {
            localStorage.setItem(MEMBER_MANAGER_REFRESH, newState.refreshToken)
        } else {
            localStorage.removeItem(MEMBER_MANAGER_REFRESH)
        }

        // Update state
        this.authState.next(newState)
    }


    hasPermission(permission: string): Observable<boolean> {
        return this.authUser$.pipe(
            map(user => {
                if (!user) return false

                if (user.user?.is_super_admin) return true

                const accountRolePermissions = user.account?.roles?.flatMap(role => role.permissions) || []
                const groupRolePermissions = user.account?.groups?.flatMap(group => group.roles.flatMap(role => role.permissions)) || []
                const allPermissions = new Set([...accountRolePermissions, ...groupRolePermissions])

                return allPermissions.has(permission)
            })
        )
    }

    isAllowedForAdminAndOakmoreMembers(): Observable<boolean> {
        const allowedStrings = [
            '@oakmorelabs.com',
            '@admin',
            'brenda@ahwus.com',
            'brandy@ahwus.com',
            'allison@ahwus.com'
        ]

        return this.user$.pipe(
            map(user => {
                if (!user?.email) return false

                const userEmail = user.email.toLowerCase()
                return allowedStrings.some(email => userEmail.includes(email))
            })
        )
    }

    isAllowedOnlyAdminEmail(): Observable<boolean> {
        const allowedStrings = [
            '@admin',
        ]

        return this.user$.pipe(
            map(user => {
                if (!user?.email) return false

                const userEmail = user.email.toLowerCase()
                return allowedStrings.some(email => userEmail.includes(email))
            })
        )
    }

    isAllowedOnlyAdmin(): Observable<boolean> {
        const allowedStrings = [
            '@admin',
            '@oakmorelabs.com',
            'ryan@oakmorelabs.com',
            'brandy@ahwus.com',
            'christina@ahwus.com',
            'sstaton@ahwus.com',
            'shaina@ahwus.com'
        ]

        return this.user$.pipe(
            map(user => {
                if (!user?.email) return false

                const userEmail = user.email.toLowerCase()
                return allowedStrings.some(email => userEmail.includes(email))
            })
        )
    }

    isAllowedToSeeACH(): Observable<boolean> {
        const allowedStrings = [
            '@admin.com',
            'sstaton@ahwus.com',
            'ryan@oakmorelabs.com',
            'nic@oakmorelabs.com',
            'petr@oakmorelabs.com',
            'shaina@ahwus.com',
        ]
        return this.user$.pipe(
            map(user => {
                if (!user?.email) return false

                const userEmail = user.email.toLowerCase()
                return allowedStrings.some(email => userEmail.includes(email))
            })
        )
    }

    getCurrentUser(): IAuthUser | null {
        return this.authState.value?.user
    }

    forgotPassword(email: string): Observable<void> {
        return this.http.post<void>(
            `auth/forgot-password`,
            {email},
            {headers: this.authHeaders}
        )

    }

    async resetPassword(token: string, newPassword: string): Promise<{success: boolean}> {
        return firstValueFrom(this.http.post<{success: boolean}>(
            `auth/reset-password`,
            {token, password: newPassword},
            {headers: this.authHeaders}
        ))
    }
}
